//Main Theme Colors
$background: white;
$textstandard: #6a737b;
$textalternative: white;
$textalternative-faded: rgba(255, 255, 255, 0.5);
$faded-background-light: rgba(255, 255, 255, 0.6);

$syscoblue: #008ace;
$syscodarkblue: #1c4f72;
$lightblue: #91c0e5;

$syscogreen: #54ae44;
$syscolightgreen: #a4c783;
$syscodarkgreen: #155a32;

$syscoyellow: #f1c40f;
$lightyellow: #fff6e3;

$syscored: #e95c56;
$syscoorange: #ff8402;
$darkorange: #e28a2a;

$bluegrey: #7d868d;
$bluegrey2: #7792a5;

$syscopurple: #8e44ad;

//Grid Colors
$gridstripes: #f7fbfd;
$gridhighlight: #fff7e4;

//Modals
$modalheader: #f6f9fc;

/***********************************************************
    SYSCO SUPPLIER SUITE 
    PRIMARY SASS FILE
    Author: Michael Lu 
    (Auto Compiled to CSS during runtime)
***********************************************************/

@charset "UTF-8";

// Initial Imports and resets
@import 'bt-ux-framework/bt-ux-framework';

//Login Imports
@import 'apps/login/login';

//Dashboard app import
@import 'apps/dashboard/_dashboard';

.wrapper.signup {
  .col-leftside-aboutyou {
    background: #f5f5f5;
    border-radius: 20px 0 0 20px;
    padding: 70px 20px;
  }
  .form-btn-link {
    padding: 15px 40px !important;
    height: auto !important;
    background: transparent !important;
    margin-top: 10px;
    color: #008cd2;
    border: none !important;
    box-shadow: none;
  }
  .col-leftside-aboutyou-model {
    background: #f5f5f5;
    border-radius: 20px 0 0 20px;
    padding: 20px 20px;
  }
  .ant-steps-item-content {
    display: block;
  }
  .ncob-steps {
    padding: 16px 0;
    display: block;
  }
  .ant-steps-small .ant-steps-item-title {
    line-height: 25px;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #1890ff !important;
    background: transparent;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #008cd2 !important;
  }

  .SyscoAssociate {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
  .SyscoAssociate:hover {
    background: #e8e8e8;
    border: 1px solid #008cd2;
  }

  .SyscoAssociate:hover {
    border: 1px solid #4dc3ff;
  }
  .SyscoAssociate-selected {
    border: 1px solid #008cd2;
  }
  .SyscoAssociate-selected .order-now-delivery {
    background: #008cd2;
    border: #008cd2 1px solid;
  }
  .SyscoAssociate-selected .order-now-delivery svg {
    color: #ffffff !important;
  }

  input:focus:invalid,
  select:focus:invalid,
  textarea:focus:invalid {
    border-color: red;
  }

  .submit-btn {
    width: 45% !important;
    padding: 15px 40px !important;
    height: auto !important;
    background: #008cd2 !important;
    margin-top: 10px;
    box-shadow: 0px 0px 4px #076695ad;
    border-radius: 9px;
    opacity: 1;
    color: white !important;
  }

  .model-body {
    background: white;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .model-header {
    box-shadow: 0 8px 6px -6px #e6e6e659;
    background: white;
  }

  .newcustomer-heading {
    color: #000000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
    text-align: left;
    margin: 2px;
    padding-left: 30px;
  }

  .col-leftside-aboutyou-model {
    background: #f5f5f5;
    border-radius: 20px 0 0 20px;
    padding: 20px 20px;
  }

  .catelog-lable {
    padding-bottom: 10px;
    display: block;
    padding-top: 10px;
  }

  .container {
    width: auto !important;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem 3rem;
    border-radius: 23px;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.55);
  }

  .title {
    font-weight: 300;
    font-size: 2rem;
    margin: 1.3rem 0;
    color: #6a737b;
    opacity: 0.7;
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  .submit-col {
    padding-top: 5px;
    text-align: right;
  }
  .Language-selection-newcustomer {
    display: block;
  }

  .model-header {
    width: 100%;
    text-align: right;
  }

  .back-btn {
    width: 45% !important;
    padding: 15px 40px !important;
    height: auto !important;
    background: #ffffff !important;
    margin-top: 10px;
    box-shadow: 0px 0px 4px #076695ad;
    border-radius: 9px;
    opacity: 1;
    color: #008cd2 !important;
  }

  .ok-col {
    text-align: center;
  }
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-input-number {
  width: 100% !important;
}

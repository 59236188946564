.organisation {
  position: relative;
  max-width: 20rem;
  height: 1.5rem;
  border-radius: 1.2rem;
  background: #7792a5;
  margin: 0 1rem;
  color: $textalternative;
  padding: 0 1.1rem;
  @include flexbox(0 0 auto, column, flex-start, center);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 12px $syscoblue;
  }

  .current-vendor {
    @include flexbox(0 0 auto, row, flex-start, center);
    height: 1.5rem;
    i {
      font-size: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  .type {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.95rem;
    border-right: 1px dotted $textalternative-faded;
    padding-right: 10px;
    height: 100%;
    @include flexbox(0 0 auto);
  }
  .name {
    @include flexbox(0 0 auto, row, flex-start, center);
    font-weight: 900;
    font-size: 0.95rem;
    padding-left: 0.8rem;
    @include truncate(13rem);
    cursor: pointer;
  }
  //Responsive
  @include breakpoint(xs) {
    display: none;
  }
  &.outlined {
    background: $background;
    color: $syscodarkblue;
    border: 1px solid $syscodarkblue;
    .type {
      border-right: 1px dotted $syscodarkblue;
      opacity: 0.6;
    }
    .name {
      opacity: 0.6;
    }
    @include breakpoint(xs) {
      @include flexbox(0 0 auto);
      z-index: $bringToTop;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      margin: 0;
      max-width: 100%;
      .name {
        @include flexbox(0 0 auto, row, flex-start, center);
        font-size: 0.75rem;
        padding-left: 0.7rem;
        @include truncate(38vw);
        text-align: left;
      }
      display: none;
    }
  }
  .vendor-switcher {
    position: absolute;
    background: $background;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    color: $textstandard;
    margin-top: 1.5rem;
    margin-left: 6rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 65%;
    z-index: $bringToTop;
    @include flexbox(0 0 auto, column, flex-start, flex-start);
    max-height: 80vh;
    overflow: auto;
    display: none;
    li {
      padding: 0.5rem 1rem;
      width: 100%;
      cursor: pointer;
      &:hover {
        color: $textalternative;
        background: $syscoblue;
      }
    }
  }
}
.location-icon {
  width: 35px;
  background-size: 30px 15px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -3px;
  background-image: url("data:image/svg+xml,%0A%3Csvg height='30px' width='30px' fill='%23008ace' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 19 28' style='enable-background:new 0 0 19 28;' xml:space='preserve'%3E%3Cpath d='M19,9.5L19,9.5L19,9.5L19,9.5C19,4.2,14.7,0,9.5,0C4.4,0,0.3,4,0,9l0,0c0,0.2,0,0.3,0,0.5l0,0l0,0c0,0.1,0,0.2,0,0.3 c0,0.1,0,0.2,0,0.3C0.1,16.8,9.5,28,9.5,28v-0.1V28c0,0,8.6-9.6,9.4-17.4v-0.1C19,10.1,19,9.8,19,9.5z M9.5,4c3,0,5.5,2.5,5.5,5.5 l0,0c0,0.2,0,0.4,0,0.6c-0.3,2.7-2.6,4.8-5.5,4.8c-2.8,0-5-2-5.4-4.7C4.1,9.9,4,9.6,4,9.3C4.1,6.4,6.5,4,9.5,4z'%3E%3C/path%3E%3C/svg%3E");
}

.customer-icon {
  width: 25px;
  background-size: 30px 15px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -3px;
  background-image: url("data:image/svg+xml,%0A%3Csvg height='30px' width='30px' fill='%23008ace' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 19 28' style='enable-background:new 0 0 19 28;' xml:space='preserve'%3E%3Cpath d='M19,9.5L19,9.5L19,9.5L19,9.5C19,4.2,14.7,0,9.5,0C4.4,0,0.3,4,0,9l0,0c0,0.2,0,0.3,0,0.5l0,0l0,0c0,0.1,0,0.2,0,0.3 c0,0.1,0,0.2,0,0.3C0.1,16.8,9.5,28,9.5,28v-0.1V28c0,0,8.6-9.6,9.4-17.4v-0.1C19,10.1,19,9.8,19,9.5z M9.5,4c3,0,5.5,2.5,5.5,5.5 l0,0c0,0.2,0,0.4,0,0.6c-0.3,2.7-2.6,4.8-5.5,4.8c-2.8,0-5-2-5.4-4.7C4.1,9.9,4,9.6,4,9.3C4.1,6.4,6.5,4,9.5,4z'%3E%3C/path%3E%3C/svg%3E");
}

.delivery-location-header {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 900;
  padding-left: 0.8rem;
}
.location-dropdown-name-address {
  display: block !important;
  width: 140px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 900;
  padding-left: 0.8rem;
}
.search-input {
  border: none;
  outline: none;
  display: block !important;
  width: 140px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 900;
  padding-left: 0.8rem;
  width: 90% !important;
}
.search-input::placeholder {
  color: #1c4f72;
}
